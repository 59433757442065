export default [
  {
    header: 'КАФЕДРАЛЬНЫЙ СОБОР В ПЕРМИ',
    picturesFolder: '1',
    picturesNumber: 3,
    text: `Комплекс Спасо-Преображенского кафедрального собора имеет большое духовное и эстетическое значение для Перми. Неслучайно именно изображение колокольни собора является одним из характерных символов города.

История Спасо-Преображенского собора начинается еще в 16 веке, с основания мужского монастыря в селе Пыскор. В 18 веке Екатерина II подписала указ о переводе Пыскорского монастыря в Пермь, где ему суждено было стать губернским центром православия.

Кафедральный Спасо-Преображенский собор стал центральным зданием ансамбля мужского Спасо-Преображенского монастыря в Перми. В него также входили архиерейский дом, духовная семинария, консистория и другие постройки. Непосредственно строительство собора начинается в 1798 году и происходит в несколько этапов: церковь Преображения Господня, колокольня, церковь во имя Стефана Великопермского. Авторами собора называют столичного архитектора Матвея Казакова и пермского – Готлиба Паульсена.

В 1932 году в здании собора размещается пермская художественная галерея. В пятидесятые годы 20 века в здании провели реконструкцию, придав собору 3-х этажное деление. Тогда же на третий этаж переезжает знаменитая экспозиция пермской деревянной скульптуры.

В настоящее время идет процесс реставрации Спасо-Преображенского собора.`,
    top: '102px',
    left: '79px',
    smallScreen: {
      top: '62px',
      left: '46px',
    }
  },
  {
    header: 'ПАРОВОЗ ЧЕРЕПАНОВЫХ, 1834 г.',
    picturesFolder: '2',
    picturesNumber: 3,
    text: `Уральский край по праву называют родиной железных дорог России. Еще в 1834 году в Нижнем Тагиле отец и сын, Ефим и Мирон Черепановы, крепостные крестьяне, изобрели и построили первый паровоз – "пароходный дилижанец", и чугунную дорогу для него. Изобретение Черепановых стало импульсом железнодорожного строительства в стране.

Технические характеристики черепановской «пароходки» были далеки от паровозов более поздней постройки: длина цилиндрического котла превышала полтора метра. Паровоз мог везти за собой в специальной повозке груз массой более 3 тонн и до 40 пассажиров со скоростью до 16 км/ч. Имелся тендер («особый фургон») для топлива и воды. В марте 1835 года Черепановы закончили постройку второго, более мощного паровоза, который уже мог перевозить до 16 тонн груза.

После успешного испытания первый локомотив Черепановых был передан, как сказали бы сегодня, в постоянную эксплуатацию. Для этой цели стараниями Мирона Ефимовича была построена первая в России паровая чугунная дорога длиной свыше восьмисот метров С постройкой второго «сухопутного парохода» рельсовую дорогу продлили, и ее общая длина составила около трех с половиной километров при ширине колеи 1645 мм. Для очистки пути от снежных заносов применялся специальный плуг с конной тягой.

Россия стала единственным государством в Европе, которое изготавливало паровозы, а не импортировало их из Англии.`,
    top: '398px',
    left: '18px',
    smallScreen: {
      top: '260px',
      left: '-3px',
    },
  },
  {
    header: 'ИМПЕРАТОР НИКОЛАЙ I',
    picturesFolder: '3',
    picturesNumber: 3,
    text: `Николай I Павлович (1796-1855) – император Всероссийский с 14 (26) декабря 1825 года, царь Польский и великий князь Финляндский. Третий сын императора Павла I и Марии Фёдоровны, родной брат императора Александра I, отец императора Александра II.

В годы правления Николая I в Российской империи были построены первые железные дороги: Царскосельская и Петербурго-Московская.

День официального открытия движения по Царскосельской дороге – 30 октября 1837 года – считается началом регулярного пассажирского сообщения в России.

1 февраля 1842 года был отмечен важным событием. Император Николай I по докладу П.П. Мельникова и Н.О. Крафта подписал высочайший Указ о сооружении железной дороги Санкт-Петербург – Москва. 1 ноября 1851 года по этой линии было открыто движение поездов.

8 сентября 1855 года Петербурго-Московская железная дорога была переименована и получила название Николаевской в честь императора Николая I. Эта железнодорожная магистраль, соединив две столицы Российской империи, показала огромные преимущества нового вида транспорта и положила начало созданию отечественной железнодорожной сети общегосударственного значения.

День железнодорожника – первый профессиональный праздник в России. Он был учрежден в 1896 году и приурочен ко дню рождения императора Николая I. Праздновался он ежегодно 25 июня вплоть до 1917 года: в этот день железнодорожники отдыхали, а вечером, как правило, в зале Павловского вокзала Царскосельской железной дороги устраивался торжественный прием с концертом. После 1917 года праздник был забыт почти на 20 лет. Традиция чествовать железнодорожников возродилась в России лишь в 1936 году.`,
    top: '342px',
    left: '115px',
    smallScreen: {
      top: '223px',
      left: '67px',
    }
  },
  {
    header: 'СЛОВА ИЗ ПЕСНИ "БОЖЕ ЦАРЯ ХРАНИ"',
    picturesFolder: '4',
    picturesNumber: 2,
    text: `«Боже, Царя храни!» – государственный гимн Российской Империи с 1833 по 1917 годы, заменивший предыдущий гимн «Молитва русских».

В 1818 состоялся шестой выпуск Института корпуса инженеров путей сообщения. Первым по успеваемости числился Алексей Федорович Львов. Судьбе было угодно, чтобы А.Ф. Львов, инженер путей сообщения, стал выдающимся композитором и непревзойденным скрипачом.

В 1833 году Львов сопровождал Николая I во время его визита в Австрию и Пруссию, где императора повсюду приветствовали звуками английского марша. По возвращении император поручил Львову, как музыканту и директору придворной Певческой хоровой капеллы, сочинить новый гимн.

Новый гимн (музыка Львова, слова Жуковского) впервые был исполнен 18 декабря 1833 года (по другим сведениям – 25 декабря) под названием «Молитва русского народа». А с 31 декабря 1833 года стал официальным гимном Российской Империи, под новым названием «Боже, Царя храни!» и просуществовал до Февральской революции 1917 года.

П.И. Чайковский использовал мелодию «Боже, Царя храни!» в сочинении №31 Славянский марш, в конце торжественной увертюры «1812 год», а также в торжественном марше по случаю коронации Александра III.

После сорокалетнего перерыва советская Россия услышала этот гимн только в 1958 году в фильме «Тихий Дон».`,
    top: '79px',
    left: '197px',
    smallScreen: {
      top: '44px',
      left: '121px',
    }
  },
  {
    header: 'ПАРОВОЗЫ УРАЛЬСКОЙ ГОРНОЗАВОДСКОЙ ЖЕЛЕЗНОЙ ДОРОГИ',
    picturesFolder: '5',
    picturesNumber: 3,
    text: `Для обслуживания Уральской Горнозаводской железной дороги Коломенский машиностроительный завод построил первые в мире пассажирские паровозы серии А типа 1-3-0 (заводской тип 20) и товарные паровозы серии Б тип 0-4-0 (заводской тип 24). Паровозы обоих типов сначала оборудовались кранами Лешателье, а затем тормозами системы Вестингауза.

Паровозы серии А имели массу в рабочем состоянии 45 т., сцепная масса составляла 37,5 т., были оборудованы парораспределительным механизмом Аллана с внутрирамным расположением кулисы, паровой машиной с двумя цилиндрами, винтовым реверсом, инжекторами Шау. Паровозы серии Б также были оборудованы инжекторами Шау и винтовым механизмом перемены хода. Паровозы серии Б (заводского типа 24) стали первыми паровозами «правительственного запаса».

Поскольку УГЖД первоначально была дорогой островного типа (не имела связи с остальной железнодорожной сетью), то техника на дорогу доставлялась водным путем.

Оба типа паровозов проявили себя в эксплуатации удовлетворительно, но дальнейшего распространения не получили, т.к. появились более мощные паровозы серии Н.`,
    top: '174px',
    left: '27px',
    smallScreen: {
      top: '108px',
      left: '12px',
    }
  },
  {
    header: 'ПАРОВОЗЫ серий К, К(У), Ж',
    picturesFolder: '6',
    picturesNumber: 3,
    text: `Паровозы серии К типа 2-3-0 (заводской тип 106) Коломенского паровозостроительного завода стали вехой в истории отечественного паровозостроения в начале ХХ в. Ряд новых конструктивных особенностей позволяли паровозам серии К лучше проходить кривые, иметь более спокойный ход при больших скоростях, увеличивали срок их службы.

В 1912 г. Коломенский завод приступил к выпуску паровозов серии Ку – усиленный (заводской тип 120). Получился удачный тип быстроходного паровоза с максимальной скоростью движения 110-115 км/час. Оба типа паровозов эксплуатировались в основном в центральной части России, на Московско-Рязанской и Московско-Казанской железных дорогах.

Для Пермской железной дороги Невским паровозостроительным заводом выпускались паровозы серии Ж типа 2-3-0 (заводской тип 74). Конструкция машины позволяла развивать скорость до 85 км/час при очень спокойном ходе, имея одинаковую мощность с паровозами серии А и Н. Еще в 1940 г. на сети железных дорог оставалось 22 паровоза серии Ж, большинство из них работали на дорогах Урала, Сибири и Дальнего Востока.`,
    top: '131px',
    left: '408px',
    smallScreen: {
      top: '79px',
      left: '257px',
    }
  },
  {
    header: 'СЛОВА ИЗ ПЕСНИ "ИНТЕРНАЦИОНАЛ"',
    picturesFolder: '7',
    picturesNumber: 3,
    text: `Интернационал (фр. L'Internationale, от лат. inter – между и natio – нация) – международный пролетарский гимн; гимн коммунистических партий, социалистов и анархистов, официальный гимн РСФСР (1918-1944), СССР (1922-1944), Дальневосточной Республики (1920-1922), Украинской ССР (1918-1949), Белорусской ССР (1919-1952).

Текст написан французским поэтом, членом 1-го Интернационала и Парижской коммуны Эженом Потье. Музыка Пьера Дегейтера (первоначально на мотив «Марсельезы»). Впервые исполнен 23 июня 1888 года и в том же году издан. Широко распространился и был переведён на множество языков. В 1910 году на конгрессе Социалистического Интернационала в Копенгагене принят как гимн международного социалистического движения.

На русский язык текст «Интернационала» перевёл в 1902 году Аркадий Яковлевич Коц. «Интернационал» в версии А.Я. Коца в России стал общепризнанным партийным гимном революционной социал-демократии, с начала 1918 года – гимном Советского государства, затем СССР.

В связи с утверждением нового Государственного гимна Советского Союза в 1944 году «Интернационал» стал официальным гимном Всесоюзной коммунистической партии (большевиков), впоследствии КПСС. А сейчас является гимном КПРФ и РКРП-КПСС, а также РКСМ.`,
    top: '72px',
    left: '592px',
    smallScreen: {
      top: '42px',
      left: '376px',
    }
  },
  {
    header: 'ЗДАНИЕ МОСКОВСКОГО Ж/Д ВОКЗАЛА, Спб',
    picturesFolder: '8',
    picturesNumber: 3,
    text: `Здание возведено в 1844-1851 годах по проекту архитектора К.А.Тона, при участии Р.А. Желязевича. Является аналогом здания Петербургского (Николаевского, Октябрьского, ныне Ленинградского) вокзала в Москве, от которого отличается лишь некоторыми особенностями.

Первым начальником вокзала стал Н.И. Миклуха. Его квартира находилась в самом здании вокзала, кроме того, здесь располагались конторы служащих Московского вокзала, управление железной дороги, императорские помещения.

В 1868 году в связи со значительно возросшим пассажиропотоком была начата реконструкция Николаевского вокзала. Был пристроен двухэтажный флигель для приёма багажа, правое крыло здания соединили с царскими покоями.

В 1898 году со стороны Лиговского проспекта было построено кирпичное здание отделения Николаевской железной дороги.

В 1912 году был объявлен конкурс на проект нового здания Николаевского вокзала, так как старое уже не справлялось с нагрузкой. Сложность решения заключалась в том, что

новый вокзал мог быть расширен только в сторону путей, так как Знаменская площадь к тому времени уже была сформирована. Одобрение получил проект В. А. Щуко. Было начато строительство корпуса прибытия, который должен был стать левым крылом нового здания. С началом Первой мировой войны строительство прервали, старое здание избежало сноса.

В 1923 году, с переименованием Николаевской железной дороги в Октябрьскую, то же название получил и вокзал, но уже в 1930 году стал именоваться Московским.

10 июня 1931 года от Московского вокзала отправился первый в стране фирменный поезд – «Красная стрела». В 1962 году, одновременно с электрификацией движения, в здании вокзала сделаны подземные переходы с выходом в город и на станцию метро «Площадь Восстания».

В конце 1950-х годов здание Московского вокзала было реконструировано и расширено по проекту архитектора В.И. Кузнецова. К его правому крылу со стороны Лиговского проспекта пристроен новый флигель со вторым вестибюлем станции метро «Площадь Восстания».`,
    top: '277px',
    left: '206px',
    smallScreen: {
      top: '176px',
      left: '127px',
    }
  },
  {
    header: 'Здание первого железнодорожного вокзала в Екатеринбурге, 1878г.',
    picturesFolder: '9',
    picturesNumber: 3,
    text: `Первый железнодорожный вокзал Екатеринбурга прослужил городу более тридцати лет. Он был построен в 1878 году по проекту архитектора П.П. Шрейбера как пассажирское здание для конечной станции Уральской Горнозаводской железной дороги. Здание было выполнено в популярном тогда псевдорусском стиле, подчёркивающем национальные черты: древнерусские терема с высоким шатром крыльца, сочетание красного и белого цветов, оформление фасада чугунными деталями. Здание второй конечной станции Пермь I было построено по тому же типовому проекту.

После появления в Екатеринбурге в 1914 году нового, более крупного вокзала в первом пассажирском здании был проведен ремонт и переустройство внутренней планировки для пассажиров IV класса (переселенцев). С началом Первой мировой войны парадные комнаты вокзала были переоборудованы под лазарет для раненых, который просуществовал до 1917 года.

В годы Великой Отечественной войны в здании вокзала разместился эвакопункт. Позднее старый вокзал стал использоваться только для принятия и отправки поездов, связанных с перемещением представителей вооружённых сил по стране.

В 2003 году к 125-летию Свердловской магистрали проведена комплексная реконструкция и реставрация здания. После чего в нем разместилась экспозиция музея истории, науки и техники Свердловской железной дороги – летописца богатой и яркой истории магистрали.`,
    top: '258px',
    left: '303px',
    smallScreen: {
      top: '169px',
      left: '190px',
    }
  },
  {
    header: 'ВЕЛИКИЙ СИБИРСКИЙ ЖЕЛЕЗНЫЙ ПУТЬ',
    subHeader: 'Здание железнодорожного вокзала в Омске',
    picturesFolder: '10',
    picturesNumber: 2,
    text: `Великий Сибирский путь – под таким названием вошла в летопись государства Российского уникальная железная дорога, соединившая Европу и Азию.

В феврале 1891 года Комитет министров принял решение о строительстве Великого Сибирского пути в направлении Миасс – Челябинск – Омск – Новониколаевск (ныне Новосибирск) – Красноярск – Иркутск – Чита – Рухлово – Хабаровск – Владивосток.

19 мая 1891 года во Владивосток прибыл наследник престола Николай Александрович. Цесаревич «изволил лично» принять участие в символической закладке Великого Сибирского пути.

В октябре 1896 года завершено строительство Западно-Сибирской железной дороги (Челябинск – Омск – Новониколаевск).

Первое здание железнодорожного вокзала в Омске было построено в 1896 году. В 1914 году, в связи с началом Первой Мировой войны и увеличением пассажиропотока, здание вокзала было реконструировано. В 1957 году здание омского вокзала полностью перестроено.

В 2004 году сдано в эксплуатацию второе здание вокзала – Омск-Пригородный. В 2006-2007 годах проведена полная реконструкция и капитальный ремонт вокзального комплекса. В ходе работ была увеличена площадь здания, вместимость залов ожидания, также построен третий этаж.`,
    top: '204px',
    left: '418px',
    smallScreen: {
      top: '131px',
      left: '263px',
    }
  },
  {
    header: 'ЦАРСКОСЕЛЬСКАЯ ЖЕЛЕЗНАЯ ДОРОГА 1837г.',
    picturesFolder: '11',
    picturesNumber: 4,
    text: `30 октября (11 ноября) 1837 года состоялось официальное открытие Царскосельской железной дороги, построенной по проекту Франца Антона фон Герстнера, – первой железной дороги России общего пользования. Эта дата является днем рождения отечественных железных дорог.

Первый поезд проехал от Петербурга до Царского Села за 35 минут со средней скоростью 51 км/ч. В 1838 году началось постоянное движение поездов до Павловска.

Открытие Царскосельской железной дороги произвело огромное впечатление на общественность. Поэт Кукольник написал стихотворение «Веселится и ликует весь народ», а композитор Глинка положил его на музыку; так возникла «Попутная песня» – первое в русской литературе произведение на железнодорожную тему.

Протяженность первой железной дороги России составляла 27 км, ширина колеи – 1829 мм. Были построены четыре пассажирские станции: Петербург, Московское Шоссе, Царское Село и Павловск.

При сооружении Царскосельской железной дороги возведены 42 малых моста и мост через Обводной канал длиной 27 метров. Парк подвижного состава к моменту открытия дороги насчитывал 6 паровозов и 16 вагонов четырех типов, построенных в Англии и Бельгии.

Царскосельская железная дорога оказала заметное влияние на развитие культурной жизни Петербурга. Павловский музыкальный вокзал, открытый 22 мая 1838 года, стал первым концертным учреждением России. В Павловске выступали И. Штраус, М.Ф. Глинка, А.К. Глазунов, Ф.И. Шаляпин, Л.В. Собинов.`,
    top: '507px',
    left: '442px',
    smallScreen: {
      top: '325px',
      left: '282px',
    }
  },
  {
    header: 'СЕРГЕЙ ЮЛЬЕВИЧ ВИТТЕ',
    picturesFolder: '12',
    picturesNumber: 2,
    text: `Граф Сергей Юльевич Витте (1849-1915) – русский государственный деятель, министр путей сообщения (1892), министр финансов (1892-1903), председатель Комитета министров (1903-1905), председатель Совета министров (1905-1906).

В феврале-августе 1892 года Витте – министр путей сообщения. За этот срок сумел устранить скопления неперевезённых грузов. Провёл реформу железнодорожных тарифов.

В 1892 году Витте назначен на пост министра финансов, который занимал в течение 11 лет. Вскоре после назначения он поднял вопрос о строительстве Великого Сибирского пути. Под его влиянием сооружение Транссибирской магистрали было резко ускорено.

В 1894 году Витте выступил за жесткие торговые переговоры с Германией, в результате чего был заключен выгодный для России 10-летний торговый договор.

Заслугой Витте является проведение денежной реформы 1897 года, в результате чего Россия на период до 1914 года получила устойчивую валюту, обеспеченную золотом, – золотой стандарт.

В августе 1896 года Витте в качестве министра финансов посетил Екатеринбург, где принял звание Почетного гражданина и соответствующий диплом. Городской голова Г.Г. Казанцев сказал, что «Сергей Юльевич Витте всегда принимал самое деятельное участие в нуждах города, и благодаря его заботам Екатеринбург сделался железнодорожным узлом».`,
    top: '252px',
    left: '502px',
    smallScreen: {
      top: '162px',
      left: '318px',
    }
  },
  {
    header: 'ПАВЕЛ ПЕТРОВИЧ МЕЛЬНИКОВ',
    picturesFolder: '13',
    picturesNumber: 3,
    text: `Павел Петрович Мельников (1804 – 1880) – российский учёный-механик и инженер, один из авторов проекта железной дороги Санкт-Петербург – Москва, первый министр путей сообщения Российской империи (1865 – 1869).

В 1835 году вышел в свет академический труд П.П. Мельникова – книга «О железных дорогах», в которой были доказаны экономическая целесообразность и техническая возможность строительства стальных магистралей в России.

На строительстве Петербург-Московской железной дороги (1842 – 1851 гг.) сложилась и оформилась русская школа строителей железных дорог и мостов во главе с талантливым русским ученым П.П. Мельниковым.

Мельников создал теоретические основы и заложил практический фундамент научной базы железнодорожного транспорта. Все направления инженерной деятельности, связанные с железными дорогами: изыскания, проектирование, строительство, подвижной состав, эксплуатация и экономика – плод многолетнего научного поиска и практических наработок П.П. Мельникова.

Многие термины в отечественной железнодорожной науке введены Павлом Петровичем и составляют основополагающий документ работы железных дорог – ПТЭ (Правила технической эксплуатации). Прежде всего, ширина колеи – 1524 мм (на европейских железных дорогах она составляет 1435 мм). От нее зависят габариты локомотивов и вагонов, размеры искусственных сооружений, габариты приближения строений. В итоге это определяет пропускную способность дороги и прохождение «кривых» (поворотов) поездом без снижения скорости.

Еще в 1844 году Мельниковым разработан проект сети железных дорог. В 1863 году проект опубликован и доработан, и в 1866 году утвержден Александром II. По этому проекту, реализованному позже, создана основа железнодорожной сети европейской части страны.

В 1862 году Мельников назначен главным управляющим путей сообщений. В 1865 году Главное управление путями сообщения и публичными зданиями преобразовано в Министерство путей сообщения (МПС), что закрепляло лидирующую роль железных дорог в транспортной системе России. Первым министром путей сообщения стал Павел Петрович Мельников.`,
    top: '200px',
    left: '536px',
    smallScreen: {
      top: '130px',
      left: '340px',
    }
  },
  {
    header: 'ПЕТР АРКАДЬЕВИЧ СТОЛЫПИН',
    picturesFolder: '14',
    picturesNumber: 3,
    text: `Петр Аркадьевич Столыпин (1862 – 1911) – государственный деятель Российской империи, статс-секретарь Его Императорского Величества (1908), действительный статский советник (1904), гофмейстер (1906). Гродненский (1902 1903) и Саратовский (1903 – 1906) губернатор, министр внутренних дел и председатель Совета министров (1906 – 1911), член Государственного совета (1907 – 1911).

В апреле 1906 года Николай II назначил Столыпина министром внутренних дел, а спустя всего три месяца – председателем Совета министров. П.А. Столыпин провел множество законопроектов, которые касались внешней политики, национального вопроса, местного самоуправления, и целый ряд законов, вошедших в историю как столыпинская аграрная реформа.

Политика Столыпина относительно Сибири состояла в поощрении переселения на её незаселённые просторы крестьян из европейской части России. Это переселение было частью аграрной реформы. В Сибирь переселились около 3 млн. человек. Они ввели в оборот 3,4 млн. десятин пустующих земель.

Для переселенцев в 1910 году были созданы специальные железнодорожные вагоны. От обычных они отличались тем, что одна их часть во всю ширину вагона предназначалась для крестьянского скота и инвентаря. Позднее, при советской власти, в этих вагонах были поставлены решетки, сами вагоны стали использоваться уже для принудительной высылки кулаков и иного «контрреволюционного элемента» в Сибирь и Среднюю Азию. Со временем же они полностью стали использоваться для перевозки заключенных.

В 1910 году Столыпин вместе с главноуправляющим земледелием и землеустройством А.С. Кривошеиным совершили инспекционную поездку в Западную Сибирь и Поволжье. Пребывание Столыпина в Екатеринбурге 6 сентября 1910 года было непродолжительным – всего 3 часа 20 минут. Он остановился в парадных комнатах вокзала и

принимал депутацию города Шадринска. Эта встреча была решающей для строительства Шадринск – Синарской железнодорожной ветви.

На заседании городской Думы Екатеринбурга 1 ноября 1910 года гласные постановили обратиться с просьбой к главе правительства не отказать им желании видеть его почетным гражданином Екатеринбурга. В марте 1911 года гласные Екатеринбургской Думы вручили ему диплом почетного гражданина города.`,
    top: '247px',
    left: '570px',
    smallScreen: {
      top: '161px',
      left: '364px',
    }
  },
  {
    header: 'ИМПЕРАТОРСКИЙ ПОЕЗД НИКОЛАЯ II 1897г.',
    picturesFolder: '15',
    picturesNumber: 4,
    text: `Императорские поезда получили известность в царствование последнего российского императора Николая II, который вообще железным дорогам России уделял особое внимание.

Внутреннее убранство вагонов императорских поездов поражало, а по инженерно-техническому решению, комфортности и безопасности они не имели подобных в Европе. Вагоны императорского поезда были выкрашены в синий цвет с золотой полосой по линии подоконников. Верхние части окон были украшены золочеными двуглавыми орлами. Крыши вагонов имели светло-серый цвет.

В 1915-1917 годах императорский поезд стал одной из постоянных резиденций последнего русского императора. В состав этого поезда входил и вагон-салон, в котором 2 марта 1917 года Николай II подписал свое отречение от престола.`,
    top: '481px',
    left: '710px',
    smallScreen: {
      top: '312px',
      left: '454px',
    }
  },
  {
    header: 'ПАРОВОЗЫ серии О (основной тип)',
    picturesFolder: '16',
    picturesNumber: 3,
    text: `В конце ХIХ века Владикавказская дорога заказала Коломенскому заводу товарный паровоз типа 0-4-0 с двухцилиндровой машиной компаунд. Эти паровозы эксплуатировались на Курско-Харьковско-Севастопольской железной дороге под особым наблюдением, а результаты работы детально рассматривались в комиссии МПС. После внесения конструктивных изменений новый четырехосный паровоз с машиной компаунд получил название «паровоз нормального типа» и был принят к серийной постройке для всех казенных железных дорог.

В начале ХХ века «паровозы нормального типа» получили наименование серии О (основной тип) и имели многочисленные модификации: ОД, ОВ, ОЧ, ОП, ОЛ (всего 8). Наибольшее распространение получили паровозы серии ОВ с парораспределительным механизмом Вальсхарта. Главное улучшение паровоза заключалось в замене радиально-маятникового механизма кулисно-маятниковым механизмом, имевшим исключительное и повсеместное распространение.

В 1905 году к паровозам «нормального типа» приравняли паровозы серии Щ, а с 1907 года начато их массовое строительство. Это привело к снижению выпуска паровозов серии О (в 1906 году было выпущено 808 паровозов, в 1907 – 315, а за весь период 1908-1928 гг. – 262 паровоза), почти все они стали поступать на промышленные предприятия (с 1908 года на железные дороги поступило всего 6 паровозов серии ОВ).

Паровозы серии О в течение почти 30 лет обеспечивали подавляющее большинство грузовых перевозок, чем и обеспечили себе признание у многих железнодорожников. Паровозы разновидностей Од и ОД получили у железнодорожников прозвище «джойка» (в честь автора парораспределительного механизма), а разновидность ОВ – «овечка», впоследствии прозвище «овечка» распространилось на все разновидности паровозов О. Паровозы серии О работали на железных дорогах страны до 1964 года.

Паровозы серии О запечатлены в кинематографе. Чаще всего снимался паровоз ОВ−324, который можно увидеть в следующих фильмах: «Сибирский цирюльник», «Анна Каренина» (2009), «Комедия строгого режима», «Край», «Статский советник» и другие. Также паровозы О можно увидеть кинолентах «Летят журавли», «Неуловимые мстители» (Од−438), «Даурия» (ОВ−1441), «Адъютант его превосходительства», «Государственная граница» и прочих.`,
    top: '91px',
    left: '830px',
    smallScreen: {
      top: '63px',
      left: '533px',
    }
  },
  {
    header: 'Здание НКПС, 1935г.',
    picturesFolder: '17',
    picturesNumber: 2,
    text: `Здание НКПС (Народного комиссариата путей сообщения) расположено в Москве на пересечении улиц Новая Басманная и Садовая-Черногрязская. Построено в 1753 – 1759 годах для размещения Главной Дворцовой канцелярии и продовольственных складов, поэтому получило название «Запасной дворец».

В 1919 году строение перешло в ведомство Наркомата путей сообщения и в 1932 –1938 годах перестроено в стиле конструктивизма по проекту архитектора Ивана Фомина.

В 1946 году в здании располагается МПС СССР, а после распада Советского Союза – Министерство путей сообщения Российской Федерации.

В 2003 году ведомство упразднено, а функция управления железнодорожным транспортом передана ОАО «РЖД».`,
    top: '268px',
    left: '891px',
    smallScreen: {
      top: '169px',
      left: '573px',
    }
  },
  {
    header: 'ПЕСНА "ШИРОКА СТРАНА МОЯ РОДНАЯ"',
    picturesFolder: '18',
    picturesNumber: 2,
    text: `Песня была написана поэтом Василием Лебедевым-Кумачом и композитором Исааком Дунаевским для фильма «Цирк» (режиссёр Григорий Александров, 1936 г.). В создании фильма также принимали участие писатели Илья Ильф и Евгений Петров, Валентин Катаев, Исаак Бабель.

Лебедев-Кумач и Дунаевский писали песню полгода, подбирая новые и новые мелодии и тексты. Некоторые песни, которые они написали в процессе этой работы, не вошли в фильм, но стали популярны, например «А ну-ка, песню нам пропой, весёлый ветер» (впоследствии была использована в картине «Дети капитана Гранта»). Авторы остановились на тридцать шестом варианте.

Песня стала очень популярной. С 1939 года первые аккорды песни, исполняемые на вибрафоне, использовалась в качестве позывных Всесоюзного радио.`,
    top: '85px',
    left: '983px',
    smallScreen: {
      top: '47px',
      left: '632px',
    }
  },
  {
    header: 'ЗДАНИЕ УПРАВЛЕНИЯ СВЕРДЛОВСКОЙ ЖЕЛЕЗНОЙ ДОРОГИ',
    picturesFolder: '19',
    picturesNumber: 4,
    text: `Здание выдержано в духе рационального неоклассицизма; строилось в период с 1925 по 1928 год. В середине 1970-х годов внесено в список памятников архитектуры федерального значения.

Здание спроектировано Константином Трофимовичем Бабыкиным (1878 – 1960) – основателем высшего архитектурного образования на Урале. Инженер Бабыкин более 20 лет посвятил работе на Пермской (ныне Свердловской) железной дороге.

При его непосредственном участии построен целый ряд знаковых для Екатеринбурга зданий: новое здание железнодорожного вокзала; здание Екатеринбургского театра оперы и балета; здание ДКЖ им. Андреева; здание Уральского научно-исследовательского химического института; комплекс зданий Уральского политехнического института.`,
    top: '283px',
    left: '1076px',
    smallScreen: {
      top: '178px',
      left: '694px',
    }
  },
  {
    header: 'ПЛОТИНА ДнепроГЭСа',
    picturesFolder: '20',
    picturesNumber: 3,
    text: `Днепрoвская гидроэлектростaнция (ДнепроГЭС) – крупная гидроэлектростанция юга Украины, пятая ступень каскада гидроэлектростанций, обеспечивает электроэнергией Донецко-Криворожский промышленный район. Станция находится в городе Запорожье и является старейшей среди каскада электростанций на реке Днепр. На основе ДнепроГЭС в Запорожье был создан металлургический, химический и машиностроительный промышленный комплекс.

ДнепроГЭС – одна из самых масштабных строек времен Советского Союза, проводилась по плану ГОЭРЛО в годы первых пятилеток (1927 – 1932 годы). Задачей ДнепроГЭС было обеспечить народное хозяйство электроэнергией, а также сделать Днепр полностью судоходным и способствовать проекту орошения полей юга Украины.

Стройка вызвала огромный энтузиазм – массы людей добровольно отправлялись на «стройку века». 10 октября 1932 года состоялся торжественный пуск ДнепроГЭСа.

В годы Великой Отечественной войны ДнепроГЭС была разрушена. В 1944 году началось восстановление. Вновь гидроэлектростанция была пущена в 1950 году, при этом ее мощность возросла на 16%.`,
    top: '118px',
    left: '1170px',
    smallScreen: {
      top: '66px',
      left: '751px',
    }
  },
  {
    header: 'ПОВЕРЖЕННЫЙ РЕЙХСТАГ',
    picturesFolder: '21',
    picturesNumber: 3,
    text: `Здание рейхстага, или Рейхстаг (Reichstagsgebaude (инф.) – «здание государственного собрания») – историческое здание в Берлине, где в 1894 – 1933 годах заседал одноимённый государственный орган, рейхстаг Германской империи и рейхстаг Веймарской республики.

В 1945 году в Советском Союзе здание германского парламента объявлено главным символом гитлеровской Германии. Сталин приказал водрузить на нём Знамя Победы.

С 1999 года в реконструированном здании рейхстага размещается бундестаг (парламент Германии).`,
    top: '86px',
    left: '1542px',
    smallScreen: {
      top: '56px',
      left: '994px',
    }
  },
  {
    header: 'Здание заводоуправления УРАЛМАШа',
    picturesFolder: '22',
    picturesNumber: 2,
    text: `Здание заводоуправления УЗТМ (Уральский завод тяжелого машиностроения) построено по проекту архитектора П.В. Оранского и немецкого архитектора Белы Шефлера, выпускника архитектурной академии Баухауз. В плане напоминает латинскую букву F. Позднее здание заводоуправления было соединено переходами с комплексом НИИ ТЯЖМАШа, в результате чего у него появился внутренний двор. Со времени постройки внешний вид здания почти не изменился, за исключением появления короба с логотипом "УЗТМ" на крыше, это более поздний элемент.

В 1928 году в Екатеринбург по приглашению главного инженера Уралмашиностроя В.Ф. Фиделра из Ленинграда приехал молодой выпускник Академии художеств Пётр Васильевич Оранский (1899 – 1960). Именно он в 1929 году создал генеральный план соцгорода Уралмаш, в основу которого заложил пять «улиц-лучей», веером расходящиеся от площади Первой пятилетки. «Пятилучье» Петра Оранского стало образцом проектирования нового социалистического города, когда жилые кварталы максимально удобно располагаются по отношению к заводской проходной вдоль пяти радиально расходящихся магистралей. При этом «ось» соцгорода является продолжением главного заводского коридора, тоже «оси», но только завода.

Оранский спроектировал для соцгорода Уралмаш парк с правильным аллеями. По его проектам выросли улицы Культуры, Сталина (ныне Орджоникидзе), Индустрии,

Кировградская и Стахановская. С его участием и под его руководством созданы знаменитый «Мадрид», Дом техучёбы, заводоуправление и заводская лаборатория.

Невозможно представить Уралмаш без творений Моисея Вениаминовича Рейшера (1902 – 1980), выпускника строительного факультета Сибирского технологического института. Именно по его проекту построен знаменитый на весь мир памятник архитектуры, водонапорная башня УЗТМ, более известная как Белая башня (1928 – 1931).

Комплекс строений заводоуправления и соцгорода построен в стиле контруктивизма.`,
    top: '405px',
    left: '843px',
    smallScreen: {
      top: '258px',
      left: '543px',
    }
  },
  {
    header: 'ПАРОВОЗЫ серии С, СУ 1-го ВЫПУСКА 1925-26гг.',
    picturesFolder: '23',
    picturesNumber: 2,
    text: `В начале ХХ века специалисты Сормовского завода приступили к созданию нового мощного паровоза типа 1-3-1. Данный проект обеспечивал лучшее распределение нагрузок по осям и спокойному ходу по прямому пути. Паровозу было присвоено обозначение серии «С» – «по проекту Сормовского завода».

В 1915 году Коломенский завод, взяв за основу проект Сормовского паровоза «С», улучшил его конструкцию и приступил к выпуску паровозов серии СВ.

В 1925 году на Коломенском заводе создан пассажирский паровоз типа 1-3-1 серии СУ (усиленный, заводской тип 152). Изменения привели к увеличению колосниковой решетки, поверхности пароперегревателя, диаметра цилиндров. Сцепной вес увеличился с 47,2 до 54,9 т. Паровоз оказался в эксплуатации очень мощным, экономичным и несложным в ремонте.

Паровозов серии СУ было 5 выпусков. Со второго выпуска в 1932 – 1936 годах в технологическом процессе изготовления узлов паровоза стала применяться сварка.`,
    top: '476px',
    left: '935px',
    smallScreen: {
      top: '306px',
      left: '602px',
    }
  },
  {
    header: 'Паровоз СУ 3-го выпуска Коломенского завода',
    picturesFolder: '24',
    picturesNumber: 2,
    text: `В годы Великой Отечественной войны паровозы СУ водили санитарные поезда. Использовались в поездной работе на главных линиях до конца 1960-х, а на второстепенных линиях примерно до 1978 г.

По эксплуатационно-экономическим показателям СУ (особенно 3-го и 4-го выпусков) соответствует лучшим мировым образцам пассажирских паровозов:

· внесены изменения в конструкцию топочной части котла;

· применён зольник двух бункерной схемы (по аналогии с паровозом ФД);

· увеличен объём песочного бункера, установленного на цилиндрической части котла;

· под передним буферным брусом установлен путеочиститель;

· над задними поддерживающими колёсами установлены грязезащитные крылья;

· изменена конструкция будки машиниста (расположение и размеры боковых окон), а крыше впервые установлен световой фонарь;

· применено жёсткое сцепление тендера с паровозом;`,
    top: '465px',
    left: '1134px',
    smallScreen: {
      top: '300px',
      left: '734px',
    }
  },
  {
    header: 'НАРКОМ ПУТЕЙ СООБЩЕНИЙ Ф.Э. ДЗЕРЖИНСКИЙ',
    picturesFolder: '25',
    picturesNumber: 3,
    text: `Народный комиссариат путей сообщения СССР (НКПС СССР, или Наркомпуть) – государственный орган СССР, управлявший деятельностью железнодорожного и других видов транспорта Советского Союза в 1923 – 1946 годах.

Феликс Эдмундович Дзержинский (1877 – 1926) – участник революционного движения в России и Польше. С декабря 1917 года председатель ВЧК. В 1921 – 1924 гг. одновременно председатель ВЧК (ГПУ, ОГПУ), нарком внутренних дел, нарком путей сообщения. С 1924 года – глава ВСНХ. Наркоматом путей сообщения руководил с 15 апреля 1921 года по 24 февраля 1924 г. Руководил восстановлением технической базы железных дорог, организовывал ритмичность работы железнодорожного транспорта, решительно боролся с хищениями, бесхозяйственностью, коррупцией.

9 декабря 1921 года по инициативе Дзержинского принят Декрет ВЦИК и СТО республики «Об охране складов, пакгаузов и кладовых, а равно сооружений на железнодорожных и водных путях сооб

РСФСР была создана Вооруженная охрана путей сообщения, остановившая массовые нападения на железнодорожные объекты, грузовые и пассажирские поезда.

В годы руководства Дзержинского создан Трансплан, внедрялись основы хозрасчета, новый Устав железных дорог (1922г.). Решались вопросы улучшения организации перевозочной работы, совершенствования ремонта локомотивов, создавалась система материальной заинтересованности работников в повышении производительности труда. В НКПС переданы технические училища.`,
    top: '231px',
    left: '1335px',
    smallScreen: {
      top: '147px',
      left: '859px',
    }
  },
  {
    header: 'Нарком путей сообщения Л.М. Каганович',
    picturesFolder: '26',
    picturesNumber: 3,
    text: `Лазарь Моисеевич Каганович (1893-1991гг.) – советский государственный, хозяйственный и партийный деятель, нарком путей сообщения (1935-1944 гг. с перерывами), член ГКО, Герой Социалистического Труда.

В годы его руководства наркоматом путей сообщения вводится общесетевой график движения поездов, единое расписание и Устав железных дорог Союза ССР. Выполнение графика движения поездов стало законом работы. Осуществлялась техническая реконструкция железных дорог. Построены паровозы серий СОК и ИС, электровоз ВЛ22, стали применяться путеукладочные краны системы В.И.Платова, создавались машинно-путевые станции, продолжалась электрификация железных дорог, установлено ежегодное празднование Всесоюзного дня железнодорожников (1936 г.).

С 1936 по 1943 год Свердловская железная дорога носила имя Кагановича`,
    top: '248px',
    left: '1385px',
    smallScreen: {
      top: '162px',
      left: '893px',
    }
  },
  {
    header: 'ВАГОН - ТЕПЛУШКА',
    picturesFolder: '27',
    picturesNumber: 3,
    text: `Теплушка – вагон, созданный на основе крытого товарного вагона и предназначенный для перевозки людей и животных. Название появилось в 1870-х годах как сокращение от «утеплённый вагон» и часто используется до сих пор.

Созданный в 1875 г. нормальный товарный вагон (НТВ) как основной тип вагона на российских железных дорогах предполагал конструктивную возможность его быстрого переоборудования для массовой перевозки людей в случае крайней надобности (то есть прежде всего для переброски войск). Для этого вагон оборудовался 2-х или 3-ярусными нарами, утеплялся изнутри деревянными щитами, в загрузочные бортовые люки вставлялись рамы со стеклами, утеплялись двери, в центре ставилась печка-«буржуйка», в дверной проём вставлялся поперечный брус для предохранения от выпадения людей при раскачивании вагона.

Для перевозки животных сооружались стойла: по четыре в каждой из половин вагона. Часто полное (по проекту) переоборудование вагона не производилось из-за недостатка времени или материалов. Стандартная вместимость теплушки на базе НТВ – 40 человек или 8 лошадей (или 20 человек + 4 лошади).

«Нормальные» теплушки массово использовались для перевозки войск, беженцев и заключенных в период с 1870-х до конца 1940-х годов.

С появлением в 1936 г. четырехосных крытых грузовых вагонов название «теплушка» стало применяться и по отношению к ним в случае их соответствующего переоборудования. Отличие в переоборудовании по сравнению с НТВ заключалось в установке 2 печек в каждой из половин вагона и в устройстве поперечных перегородок, делящих вагон на 3 отсека (впрочем, часто это не делалось).`,
    top: '471px',
    left: '1367px',
    smallScreen: {
      top: '301px',
      left: '881px',
    }
  },
  {
    header: 'БРАНДЕНБУРГСКИЕ ВОРОТА',
    picturesFolder: '28',
    picturesNumber: 3,
    text: `Бранденбургские ворота (нем. Brandenburger Tor) – архитектурный памятник в центре Берлина в районе Митте. Возведен в 1788 – 1791 г.

Бранденбургские ворота – единственные сохранившиеся городские ворота Берлина. Они являются первой значимой работой, выполненной в стиле берлинского классицизма. Образцом для Бранденбургских ворот послужили Пропилеи Акрополя в Афинах. Фасад ворот был первоначально выкрашен в белый цвет.

Во время Второй мировой войны Бранденбургские ворота получили серьёзные повреждения, квадрига была полностью разрушена. С 1945 по 1957 год на квадриге развевался флаг СССР, который затем был заменён на флаг ГДР (с 1989 года после объединения Германии на воротах нет никакого флага).`,
    top: '86px',
    left: '1672px',
    smallScreen: {
      top: '55px',
      left: '1080px',
    }
  },
  {
    header: 'СЛОВА ИЗ ПЕСНИ "МОЙ АДРЕС - СОВЕТСКИЙ СОЮЗ"',
    picturesFolder: '29',
    picturesNumber: 1,
    text: `В 1972 году композитор Давид Тухманов сочинил мелодию для стихотворения Владимира Харитонова. Так появился на свет текст песни «Мой адрес – Советский Союз», ставший негласным гимном советской эпохи. Песня вошла в репертуар ансамбля «Самоцветы».`,
    top: '42px',
    left: '1811px',
    smallScreen: {
      top: '26px',
      left: '1170px',
    }
  },
  {
    header: 'ВЫВОЗ РАКЕТЫ НА СТАРТ ТЕПЛОВОЗ ТЭМ2',
    picturesFolder: '30',
    picturesNumber: 3,
    text: `В 1959 году на Брянском машиностроительном заводе разработан проект усиленного тепловоза серии ТЭМ1. Для этого тепловоза Пензенским дизельным заводом на базе дизеля 2Д50 разработан более мощный дизель ПД1 (Пензенский дизель, 1-й тип).

В 1960 году завод выпустил два, а в 1961 ещё один маневровый тепловоз повышенной мощности, которые получили обозначение серии ТЭМ2.

Мощный маневровый односекционный тепловоз ТЭМ2 с электрической передачей широко используется по всей сети железных дорог.

Запасы топлива, масла, воды, песка обеспечивают работу локомотива на маневрах продолжительное время (до 10 суток) без экипировки. Тепловоз легко вписывается в кривые радиусом до 80 метров и может работать двумя секциями при управлении с одного поста.

Тепловоз ТЭМ2УГМК является результатом глубокой модернизации ранее выпущенных тепловозов серии ТЭМ2 различных индексов. Модернизация проводится Шадринским автоагрегатным заводом по проекту компании Woodward-MEGA. Подобная модернизация может быть проведена тепловозам серий ТЭМ18, ТЭМ15 и других, в основе которых лежит конструкция тепловоза ТЭМ2.`,
    top: '70px',
    left: '2050px',
    smallScreen: {
      top: '41px',
      left: '1324px',
    }
  },
  {
    header: 'Министр МПС СССР Б.П. Бещев',
    picturesFolder: '31',
    picturesNumber: 3,
    text: `Борис Павлович Бещев (1903 – 1981) – советский государственный деятель, министр путей сообщения СССР (1948 – 1977), Герой Социалистического Труда (1959), член ЦК КПСС с 1952 года, депутат Верховного Совета СССР 4-8 cозывов.

Окончил Ленинградский институт инженеров путей сообщения. После института работал инженером, заместителем начальника станции, а с 1936 года – в аппарате НКПС. Начальник Орджоникидзевской дороги (1937г.). Особоуполномоченный НКПС, начальник Октябрьской дороги (1940 г.), начальник управления дорог Северо-Западного направления, член Коллегии НКПС (1941г.), уполномоченный НКПС по Северному, а позднее Ленинградскому фронту, организовывал воинские и эвакуационные перевозки. В 1942 – 1944 гг. – начальник Куйбышевской дороги, заместитель наркома путей сообщения и начальник Центрального управления движения. В 1946 – 1948 гг. – первый заместитель министра.

В течение почти 29 лет бессменно возглавлял железнодорожную отрасль СССР (по продолжительности пребывания в должности является рекордсменом среди советских министров). Под началом Б.П. Бещева осуществлен перевод железных дорог с паровозной на тепловозную, а затем и электрическую тягу, ручные стрелки заменены на диспетчерскую электроцентрализацию, внедрены автоблокировка, маршрутизация перевозок. Впервые в стране запущены тяжеловесные и сдвоенные грузовые поезда; проведены проектно-изыскательские работы и начата реконструкция Байкало-Амурской магистрали.

В период его руководства отраслью грузооборот железнодорожного транспорта в Советском Союзе вырос в 8 раз. При Б.П. Бещеве построено 23,5 тысяч км новых главных путей, а вместе со вторыми и третьими путями – более 40 тысяч км. Ранее существовавшие 56 железных дорог СССР преобразованы в 26 крупных магистралей, а сама сеть железных дорог впервые начала развиваться по комплексному плану.`,
    top: '184px',
    left: '2150px',
    smallScreen: {
      top: '116px',
      left: '1388px',
    }
  },
  {
    header: 'Министр МПС СССР Н.С. Конарев',
    picturesFolder: '32',
    picturesNumber: 2,
    text: `Николай Семёнович Конарев (1927 – 2007) – министр путей сообщения СССР (1982–1990). Депутат Верховного Совета СССР 11 созыва (1984 – 1989) от Грузинской ССР. Член ЦК КПСС (1986 – 1990). Почётный гражданин города Харькова.

В январе 1976 года назначен заместителем министра путей сообщения, а в январе 1977 года – первым заместителем министра с одновременным руководством Главным управлением движения поездов. С 29 ноября 1982 года по 8 мая 1991 года – министр путей сообщения. Доктор технических наук.

По инициативе Конарева, после проведённых испытаний, была увеличена загрузка товарных вагонов, что позволило значительно повысить эффективность перевозок грузов. В 1988 году отрасль достигла наивысших показателей не только в стране, но и в мировой практике, превзойдя, к примеру, американские железные дороги по объёмам перевозок в 2 раза, по съёму продукции с 1 километра пути – в 5 раз. Железные дороги Советского Союза установили мировой рекорд грузовых перевозок – 4 млрд. 116 млн. тонн.

Производственную деятельность Н.С. Конарев всегда успешно совмещал с научной. Им опубликовано более 50 научных трудов, разработана теория железнодорожных стыков, комплексная технология железнодорожных перевозок и др.`,
    top: '220px',
    left: '2199px',
    smallScreen: {
      top: '139px',
      left: '1420px',
    }
  },
  {
    header: 'БАЙКАЛО-АМУРСКАЯ МАГИСТРАЛЬ',
    picturesFolder: '33',
    picturesNumber: 5,
    text: `В апреле 1932 года СНК СССР принял решение о строительстве Байкало-Амурской магистрали (БАМ) – второй широтной дороги Восточной Сибири и Дальнего Востока для освоения природных богатств территорий, лежащих к северу от Амурской дороги. В соответствии с этим решением развернулись строительные работы.

Новый этап в строительстве БАМа приходится на 80-е годы 20 века. БАМ становится главным строительным полигоном на железнодорожном транспорте, 1700 км новых путей. «Золотое звено» всего БАМа было уложено 1 октября 1984 г. в Куанде. 1 ноября 1989 г. был принят в постоянную эксплуатацию 21-й пусковой комплекс БАМа – обход Северомуйского тоннеля и завершена приемка всей магистрали.

Следующий этап строительных работ по развитию БАМа начался в 90-х годах и ставил целью экономическое развитие этого региона. 30 марта 2001 г. осуществлена сбойка Северомуйского тоннеля.`,
    top: '59px',
    left: '2309px',
    smallScreen: {
      top: '34px',
      left: '1491px',
    }
  },
  {
    header: 'Тепловоз 2ТЭ121, 1978г.',
    picturesFolder: '34',
    picturesNumber: 1,
    text: `Тепловоз 2ТЭ121 – магистральный грузовой тепловоз с электропередачей, эксплуатировался на железных дорогах СССР, России и Украины.

В 60-х годах XX века МПС выступило инициатором заказа грузовых тепловозов с электропередачей секционной мощностью 4000 л.с. На основании проведённого в те годы исследования эффективности применения различных типов тепловозов на перспективу, выполненному ИТКП и ЦНИИ МПС, были сделаны выводы о целесообразности иметь в общем парке локомотивов не менее 30 % тепловозов мощностью 4000 л. с. в секции.

Построенный в 1978 году тепловоз 2ТЭ121-001 имел нагрузку от колёсных пар на рельсы 26,8 тс, что соответствовало требованиям к первому опытному образцу. В том же 1978 году тепловоз прошёл путевые и динамические испытания на участке Майкоп – Белореченская Северо-Кавказской железной дороги, которые были проведены ВНИИЖТ совместно с ВНИТИ и заводом-изготовителем при движении со скоростью до 120 км/ч. По результатам испытаний был сделан ряд предложений об изменении конструкции.

В настоящее время некоторые технические решения 2ТЭ121 используются на других локомотивах, например, тяговый привод электровоза ЭП1 базируется на решениях 2ТЭ121. Кроме того, за время создания этого тепловоза был накоплен большой научный опыт, особенно по динамике и прочности локомотивов, который может быть полезен при создании новых конструкций. Заслуживает внимания и достигнутый на этом тепловозе уровень комфорта локомотивной бригады и плавности хода.`,
    top: '81px',
    left: '2426px',
    smallScreen: {
      top: '46px',
      left: '1566px',
    }
  },
  {
    header: 'ДОМА СТАЛИНСКОГО ВРЕМЕНИ',
    picturesFolder: '35',
    picturesNumber: 3,
    text: `«Сталинки», сталинские дома – общее разговорное название многоквартирных домов, сооружавшихся в СССР с конца 1930-х годов до середины 1950-х годов, главным образом во время правления И.В. Сталина, преимущественно в стиле неоклассицизм (сталинский ампир).

«Сталинки» представляют собой капитальные многоквартирные дома высотой от 2 этажей со стенами из негорючих материалов, обеспеченные коммунальными удобствами: водопроводом, канализацией, водяным отоплением.

Фактическое строительство «сталинок» прекратилось примерно к 1960 году, когда в крупнейших городах было завершено возведение значимых проектов, не подлежавших изменению. Так, в кадры одного из сюжетов фильма «Старик Хоттабыч» (Ленфильм, 1957г.), снятого в Московском парке Победы, попадает не завершённое строительство 12-этажных секций дома №44 по Кузнецовской улице, силуэт которого по плану замыкает одну из центральных меридиональных аллей парка.

Стиль домов довоенной постройки иногда атрибутируют как постконструктивизм, а послевоенных – как «сталинский ампир».`,
    top: '449px',
    left: '1756px',
    smallScreen: {
      top: '284px',
      left: '1134px',
    }
  },
  {
    header: 'Квартальная застройка 60х-80х годов XX века',
    picturesFolder: '36',
    picturesNumber: 3,
    text: `В 1955 году принято постановление «Об устранении излишеств в проектировании и строительстве», положившее конец сталинскому ампиру.

Индустриализация прежде всего коснулась жилищного строительства: было необходимо решить вопрос о типе массовой квартиры и жилого дома. Началась застройка районов крупными массивами.

Благодаря крупномасштабному жилищному строительству 60-х – 80-х годов люди вселялись в квартиры, обладающие стандартным набором основных современных удобств (холодная и горячая вода, канализация, центральное отопление, газовая или электрическая плита) и тем самым приобщались к материальным условиям жизни, почти совершенно неизвестным поколению их родителей.

Большой процент удовлетворенных комфортабельностью жилища был обусловлен тем, что представление о нем сформировалось в массовом сознании во времена аскетизма. Поэтому уже само наличие отдельной квартиры воспринималось как высокое благо. К тому же, сама социально-архитектурная среда новых застроек складывалась как новое пространство человеческого взаимодействия и самоуправления – скверики, обсаженные жильцами на субботниках по благоустройству дворовых территорий кустами сирени и черемухи, липами, кленами, со временем превратились в новые, исключительно уютные зеленые зоны отдыха горожан. Детские площадки, беговые дорожки, мини-стадионы добавили понятию «двор» особый смысл и содержание.`,
    top: '209px',
    left: '1984px',
    smallScreen: {
      top: '125px',
      left: '1284px',
    }
  },
  {
    header: 'ДОМА-ХРУЩЁВКИ',
    picturesFolder: '37',
    picturesNumber: 3,
    text: `Дома хрущëвской постройки (разговорное «хрущёвки») – советские типовые серии панельных и кирпичных жилых домов, массово строившиеся в СССР с конца 1950-х по начало 1980-х гг. Название связано с Н.С. Хрущёвым, во времена пребывания которого на посту руководителя СССР был разработан проект этих домов. Относятся к архитектуре функционализма.

Первые экспериментальные каркасно-панельные дома были сооружены в 1948 году в Москве на Соколиной горе и Хорошëвском шоссе по проектам, разработанным, соответственно Госстройпроектом (при участии Академии архитектуры СССР) и Мосгорпроектом. Автором проекта жилых домов был В.П. Лагутенко. Вначале эти дома высотой в четыре этажа сооружались со стальным каркасом, но из-за большого расхода металла (более 16 кг на 1 м³ здания) вскоре перешли на сборный железобетонный каркас (расход стали снизился до 3,75 кг на 1 м³). С 1950 года кроме каркасно-панельных домов со связанными стыками в Москве, Ленинграде, Киеве, Магнитогорске и других городах началось сооружение бескаркасных панельных домов.

Первые проекты «хрущёвок» имели черепичные или шиферные крыши, однако уже обладали характерной планировкой. В рамках кампании по борьбе с архитектурными излишествами здания получили более дешёвые в исполнении плоские битумные крыши с низким чердачным пространством, обычно засыпанным изоляционным материалом и имеющим лишь вентиляционные отверстия наружу.

В оформлении фасадов зданий отсутствуют лепнина и другие декоративные элементы, характерные для зданий предыдущего, сталинского периода. Кирпичные хрущёвки в подавляющем большинстве не оштукатурены.`,
    top: '347px',
    left: '1752px',
    smallScreen: {
      top: '226px',
      left: '1127px',
    }
  },
  {
    header: 'СВЕРДЛОВСК-44 (Новоуральск)',
    picturesFolder: '38',
    picturesNumber: 3,
    text: `Город Новоуральск является закрытым административно-территориальным образованием в соответствии с законом Российской Федерации «О закрытом административно-территориальном образовании». Многие его помнят как Свердловск-44 по индексу «почтового ящика».

В начале Второй мировой войны советское правительство приняло ряд важнейших постановлений о реконструкции существующих и строительстве новых авиационных заводов. Для самолетов требовались легкие сплавы. Началось строительство трех заводов по переплавке лома легких металлов, один из них – завод №484 на Урале, рядом с железнодорожной станцией Верх-Нейвинск. Здесь было все необходимое: близость предприятий металлургической промышленности, сырьевые, энергетические, водные и лесные ресурсы, наличие квалифицированных кадров. В 1944 году, после объединения двух площадок, завод получил новый номер – №261.

Промышленная площадка завода №261, согласно Постановлению СНК СССР от 1 декабря 1945 года, была передана под строительство нового завода по производству обогащенного урана. С начала 1946 года началось строительство нового завода и жилого поселка. В июне 1949 года важнейшее задание партии и правительства было выполнено – сдана в эксплуатацию первая очередь Уральского электрохимического комбината и получена первая продукция – 75% изотопа U-235.

Тринадцать лет строился населенный пункт, менялись градообразующие предприятия: заводы №484, №261, СУМЗ-УЭХК, а заводской поселок все еще не имел своего названия и органов местного самоуправления. И только 17 марта 1954 года Указом президиума Верховного Совета РСФСР безымянный населенный пункт получил статус города и свое название Ново-Уральск (так в Указе), но почти 40 лет продолжал жить под чужим «номерным» имением – Свердловск-44.

Проблема переименования Свердловска-44, как и всех «закрытых» городов (Красноярск-26, Томск-7 и т. д.), возникла осенью 1991 года в связи с запросом органов геодезии и картографии о том, под каким названием наносить город на новую российскую карту.

Постановление Правительства РФ от 4 января 1994 года расставило все точки над «I». «Закрытым» городам вернули названия, официально закрепленные за ними и означенные в 1954 году. Гриф секретности с названия сняли, однако появилась новая аббревиатура «ЗАТО» – закрытое административно-территориальное образование. С 1996 года ЗАТО объединяет город Новоуральск и сельские населённые пункты – посёлок Мурзинка, село Тарасково, деревню Починок, деревню Пальники и деревню Елани. ЗАТО имеет единую территорию, органы местного самоуправления, муниципальную собственность и бюджет.`,
    top: '331px',
    left: '1862px',
    smallScreen: {
      top: '212px',
      left: '1204px',
    }
  },
  {
    header: 'Уральский электромеханический институт инженеров железнодорожного транспорта, 1956г.',
    picturesFolder: '39',
    picturesNumber: 3,
    text: `Уральский государственный университет путей сообщения (УрГУПС) основан 23 июня 1956 года в гoрoде Свердловске Советом министров СССР по представлению Министерства путей сообщения. До 1994 года был известен как Уральский электрoмехaнический институт инженеров железнодорожного транспорта (УЭМИИТ); в

период с 1994 по 1999 носил название «Уральская государственная академия путей сообщения» (УрГАПС).

Создание университета связано с потребностями в специалистах по электрификации на железных дорогах Урала того времени. Подготовка велась по двум направлениям: «Электрификация железнодорожного транспорта» и «Автоматика, телемеханика и связь на железнодорожном транспорте».

Чрезвычайно ответственной для вуза стала задача первого набора студентов. Дело в том, что набор в институт был объявлен только в июле 1956 г. К этому времени другие вузы уже проводили вступительные экзамены. Но, тем не менее, на 225 мест было подано 638 заявлений. В институт было зачислено 228 абитуриентов, в том числе 93 медалиста. Ввиду ограниченности контингента студентов в первом учебном году ещё не было факультетов и деканатов, и руководство учебным процессом осуществлялось непосредственно ректоратом. Изначально со студентами занимались 16 штатных преподавателей пяти кафедр: высшая математика, химия, графика, кафедра общественных наук и физического воспитания. Позже открылись кафедры физики, теоретической механики, технологии материалов, теоретических основ электротехники, иностранных языков, электрических машин, электрической тяги, энергоснабжения электрических железных дорог, связи и т.д.

В 1958 году созданы электромеханический и электротехнический факультеты, в 1959 г. – заочный, в 1966 г. – строительный факультет. В том же году была организована кафедра эксплуатации железных дорог, впоследствии переименованная в факультет управления перевозочным процессом. В 1968 г. была организована кафедра вагонов и вагонного хозяйства, а также открыт факультет повышения квалификации руководящих работников и специалистов железнодорожного транспорта. В 1971 г. образовался механический факультет. В 1975 г. начали работу кафедры строительных конструкций и строительного производства.

16 ноября 1964 года открылись двери первой очереди главного учебного корпуса. В просторные аудитории, лаборатории и кабинеты этого корпуса вселились общеобразовательные, а также все кафедры электромеханического факультета. В последующие годы были введены в эксплуатацию вторая и третья очереди учебного корпуса, общежития, жилые и другие здания. Так сформировался в городе второй, после УПИ, студенческий городок.`,
    top: '252px',
    left: '1891px',
    smallScreen: {
      top: '165px',
      left: '1228px',
    }
  },
  {
    header: 'Электровоз ВЛ8, после 1963г.',
    picturesFolder: '40',
    picturesNumber: 3,
    text: `ВЛ8 («Владимир Ленин», 8-осный) – советский магистральный грузовой электровоз постоянного тока с осевой формулой 2о+2о+2о+2о, выпускавшийся с 1953 по 1967 год. До 1961 года (до появления ВЛ10 и ВЛ80) был сильнейшим локомотивом страны. В 1960-х наравне с ВЛ60 и ТЭ3 являлся одним из основных локомотивов на советских железных дорогах. Явился технической основой ВЛ23.

На электровозе ВЛ8-948 по проекту ПКБ ЦТ МПС в 1968 году были установлены вторые дополнительные опоры кузова, применены более мягкие рессоры, при которых статический прогиб их увеличился до 100 мм, поставлены упорные резиновые амортизаторы в роликовых буксах. Однако, как показали испытания, проведённые ЦНИИ МПС, поднять скорость электровоза при этих изменениях оказалось возможным лишь до 90 км/час. Поэтому от внедрения перечисленных выше изменений в дальнейшем отказались.

В 1973 году Всесоюзный научно-исследовательский тепловозный институт (ВНИТИ) изменил рессорное подвешивание на электровозе ВЛ8-321: были поставлены цилиндрические пружины между балансиром и рамой тележки, по четыре пружинных опоры от секций кузова на рамы тележек; одновременно были поставлены упоры в буксах по типу букс тепловозов ТЭ3. Статический прогиб рессорного подвешивания достиг при этом 122 мм. Испытания этого электровоза дали положительные результаты: возможность повышения максимальной скорости по условиям воздействия на путь до 100 км/час. Это послужило основанием для начала проведения работ по модернизации рессорного подвешивания электровозов ВЛ8.`,
    top: '466px',
    left: '2007px',
    smallScreen: {
      top: '301px',
      left: '1297px',
    }
  },
  {
    header: 'Электровоз Н8',
    picturesFolder: '41',
    picturesNumber: 1,
    text: `В 1952 году под руководством главного конструктора Новочеркасского электровозостроительного завода Б.В. Суслова началось проектирование нового электровоза, а в марте 1953 года уже был изготовлен первый опытный восьмиосный электровоз Н8-001. Серия Н8 означала: Новочеркасский, восьмиосный.

На новом электровозе были применены принципиально новые тележки литой конструкции, подобные примененным на американских тепловозах ДБ. Все буксы были оборудованы подшипниками качения. Рессорное подвешивание, состоявшее из надбуксовых цилиндрических пружин и листовых рессор, было сбалансированным по каждой стороне тележки. Кузов электровоза был впервые выполнен без переходных площадок, полуобтекаемой формы. Двери располагались с боковых сторон кузова.

Для электровоза были вновь спроектированы новые тяговые электродвигатели НБ-406А с ненасыщенной магнитной системой, что позволяло им реализовывать полную мощность в большем диапазоне частот вращения. При напряжении на зажимах 1500 В эти ТЭД развивали длительную мощность 470 кВт и часовую 525 кВт.

Секции Н8 были постоянно механически и электрически соединены между собой и могли разъединяться только при ремонтах. Все силовые цепи были общими для обеих секций, что позволяло при последовательном соединении собирать в последовательную цепь все восемь ТЭД. На электровозе было реализовано рекуперативное торможение с противокомпаундированием возбудителей для уменьшения массы мотор-генераторов.`,
    top: '456px',
    left: '2099px',
    smallScreen: {
      top: '294px',
      left: '1360px',
    }
  },
  {
    header: 'Здание Верховного Совета СССР в Кремле',
    picturesFolder: '42',
    picturesNumber: 2,
    text: `14-й корпус Кремля – административное здание, располагавшееся на Ивановской площади Московского Кремля с 1934 по 2016 год. Построено на месте Малого Николаевского Дворца, Чудова и Вознесенского монастырей, которые были снесены в 1929 – 1930 годах. Архитектором проекта выступал Владимир Апышков при непосредственном участии Ивана Рерберга.

Изначально в корпусе располагалась школа военного состава РККА, позднее его занимали комендатура Кремля и секретариат Президиума Верховного Совета СССР. После распада Советского Союза в здании находились резервный кабинет и администрация президента. В 2001 году в помещениях начались ремонтные работы, которые с перерывами продлились до 2014 года, когда было принято решение демонтировать постройку. Весной 2016-го на этом месте открылся парк с застеклёнными шурфами, открывающими фундаменты древних монастырей.`,
    top: '455px',
    left: '2285px',
    smallScreen: {
      top: '296px',
      left: '1478px',
    }
  },
  {
    header: 'СЛОВА ИЗ ГИМНА РОССИИ',
    top: '28px',
    left: '2708px',
    text: `Государственный гимн Российской Федерации – один из официальных государственных символов РФ, наряду с флагом и гербом. Музыка и основа текста восходят к гимну СССР.

В 1943 году Сергей Михалков совместно с военным журналистом Георгием Эль-Регистаном (Габриэль Аркадьевич Уреклян) написал текст к государственному гимну СССР, который впервые был исполнен в новогоднюю ночь 1944 года. В 1977 году он создает вторую редакцию гимна. В 1993 году Сергей Михалков постановлением правительства РФ был введен в состав комиссии (сопредседатель) по созданию гимна Российской Федерации. В 2001 году он в третий раз становится автором текста гимна, теперь уже Российской Федерации.`,
    smallScreen: {
      top: '16px',
      left: '1749px',
    }
  },
  {
    header: 'Космодром "Восточный". Тепловоз ТЭМ7А вывозит на старт ракету',
    picturesFolder: '44',
    picturesNumber: 2,
    text: `ТЭМ7 – маневровый тепловоз с электрической передачей переменно-постоянного тока, с осевой формулой 2о+2о−2о+2о. До недавнего времени самый мощный маневровый тепловоз, эксплуатирующийся на железных дорогах стран постсоветского пространства.

ТЭМ7А. Данная модификация тепловоза выпускается Людиновским заводом с 1988 года по настоящее время. По данным на июль 2018 года выпущено 552 тепловоза этой серии. С 2008 года выпускается модернизированный вариант ТЭМ7А, который, тем не менее, не получил отдельной литеры в названии серии и отдельного диапазона заводских номеров. Условно считается, что модернизированные ТЭМ7А выпуска после 2008 года имеют номера с 0300`,
    top: '217px',
    left: '2823px',
    smallScreen: {
      top: '139px',
      left: '1823px',
    }
  },
  {
    header: 'Порт Усть-Луга',
    picturesFolder: '45',
    picturesNumber: 3,
    text: `Усть-Луга – морской торговый порт на северо-западе России, в Ленинградской области, в Лужской губе Финского залива Балтийского моря вблизи посёлка Усть-Луга. Начал работу с открытия в декабре 2001 года угольного терминала, существующий лесной терминал в устье реки Луги был включён в состав порта.

Железнодорожное сообщение осуществляется через сеть железных дорог по линии Мга – Гатчина – Веймарн – Усть-Луга, примыкающей к железнодорожной магистрали Санкт-Петербург – Таллин. Для обеспечения деятельности порта ОАО «РЖД» провело реконструкцию существующих путей и строительство новых парков станции Лужская.

При строительстве Усть-Лужского железнодорожного узла применены современные технологические решения в сфере ресурсосбережения, безопасности движения поездов, малолюдных технологий и электронного документооборота, вобравшие в себя ряд инновационных решений отечественных и зарубежных разработчиков. При выходе порта Усть-Луга на полную мощность выгрузка на станции Лужская составит более 3500 вагонов в сутки.`,
    top: '66px',
    left: '2952px',
    smallScreen: {
      top: '35px',
      left: '1909px',
    }
  },
  {
    header: 'ПАНОРАМА САБЕТТЫ',
    picturesFolder: '46',
    picturesNumber: 3,
    text: `Сабетта – вахтовый посёлок в Ямальском районе Ямало-Ненецкого автономного округа, расположен на восточном берегу полуострова Ямал у Обской губы Карского моря.

В упразднённой в 2006 году одноимённой деревне (посёлке) Сабетта по переписи 2002 года проживало 19 жителей, в том числе русские – 12 (63 %), коми – 5 (26 %), другие – 2 (11 %). В 2011 году началось строительство инфраструктуры вахтового посёлка Сабетта, рассчитанного на 3500 жителей.

Глава «НОВАТЭК» Леонид Михельсон заявил в ноябре 2016 года, что в Сабетте работают более 22 000 человек. Также в течение 2016 года фигурировали оценки в 15 000 и 17 000 человек. По оценке декабря 2017 года, в Сабетте создано более 30 000 рабочих мест.

В январе 2016 года Правительство ЯНАО определилось с частным партнёром по строительству железной дороги «Бованенково – Сабетта». Проектирование и строительство железной дороги от Бованенково ожидается завершить уже в конце 2019 года. Протяжённость железной дороги – более 170 км, а стоимость реализации проекта составит более 113 млрд. рублей.`,
    top: '165px',
    left: '3044px',
    smallScreen: {
      top: '102px',
      left: '1969px',
    }
  },
  {
    header: 'Здание железнодорожного вокзала в Екатеринбурге',
    picturesFolder: '47',
    picturesNumber: 3,
    text: `Начало XX века в России характеризовалось бурным железнодорожным строительством. Резко увеличился поток пассажиров, и старый вокзал с ним уже не справлялся. Поэтому стал вопрос о возведении нового вокзала. Здание должно было подчеркивать растущую промышленную мощь Урала и его центра – Екатеринбурга.

В то время широкое распространение получил модернистско-функциональный стиль. Построенное в 1914 году здание стало простым и строгим, но сложным в инженерном отношении сооружением. Поскольку станционные пути проходили по насыпи, был проложен тоннель. Со стороны привокзальной площади здание имело высокий этаж, а со стороны перрона – два низких.

В конце 30-х годов по проекту архитектора Г.Валенкова здание вокзала было надстроено вторым этажом, украшено колоннадой, приобрело величественный, монументальный облик в стиле неоклассики и стало соответствовать понятию «ворота города».

В 1961-1962 годах вокзал был расширен за счёт двух пристроек с арочными проходами, удлинён пешеходный тоннель, а в 1995 году в соответствии с проектом развития станции Свердловск-Пассажирский с западной стороны действующего вокзала достроено третье здание. Автор проекта О. Утгоф.

В 1997 году проведен капитальный ремонт с сохранением исторического облика вокзала. Залы и центральная лестница отделаны традиционными уральскими материалами: змеевик, гранит, мрамор. Своды украшены фресками с сюжетами о становлении и развитии Урала.

В преддверии встречи болельщиков Чемпионата мира по футболу на вокзале Екатеринбург отремонтированы внутренние помещения и фасад здания, обновлена навигация, модернизированы первая и вторая пассажирские платформы, выполнено ограждение территории, организованы досмотровые зоны с металлодетекторами и рентгеновскими установками, подключена интеллектуальная система видеонаблюдения.`,
    top: '359px',
    left: '2650px',
    smallScreen: {
      top: '233px',
      left: '1711px',
    }
  },
  {
    header: 'Железнодорожный вокзал в Адлере',
    picturesFolder: '48',
    picturesNumber: 3,
    text: `Адлер – железнодорожная станция Краснодарского региона Северо-Кавказской железной дороги, расположена в Адлерском районе города Сочи. По характеру основной работы является пассажирской, по объёму выполняемой работы относится к внеклассным станциям.

28 октября 2013 года ОАО "РЖД" открыло новый железнодорожный вокзал в Адлере. На церемонии открытия присутствовал президент РФ Владимир Путин.

Строительство вокзала велось в русле общемировой тенденции создания крупных интермодальных пассажирских транспортных узлов – хабов, где пассажир может не только совершить пересадку или оставить багаж, но и получить большой спектр дополнительных услуг: от приобретения свежей прессы до гостиничного сервиса. Вокзал Адлер является транспортно-пересадочным узлом, объединяющим все виды пассажирского транспорта: железнодорожный, автомобильный, воздушный и морской (при перспективном строительстве причалов). Терминал связан интермодальным сообщением с аэропортом города Сочи.

На семи уровнях терминала располагаются все необходимые для функционирования терминала и обслуживания пассажиров помещения. Общая площадь комплекса – почти 30 тыс. кв. м. В нем единовременно смогут разместиться около 2 тыс. пассажиров. Пропускная способность составляет 15 тыс. человек в час. В сутки новый терминал может обслуживать 56 пар поездов дальнего и пригородного сообщения, а также 4 поезда-аэроэкспресса в час.

На верхнем уровне вокзального комплекса расположена рекреационная зона, атриум с зимним садом. Под светопрозрачными навесами размещаются зеленые насаждения и фонтаны. При строительстве вокзала использованы все самые современные технологии, в том числе в области энергосбережения. На крыше здания смонтированы тонкопленочные модули солнечных батарей для обеспечения вокзала электроэнергией.`,
    top: '284px',
    left: '2674px',
    smallScreen: {
      top: '184px',
      left: '1729px',
    }
  },
  {
    header: 'СОВРЕМЕННЫЙ РЕЛЬСОВЫЙ ПУТЬ',
    picturesFolder: '49',
    picturesNumber: 1,
    text: `Сегодня общая длина линий ОАО «РЖД» превышает 85 тыс. км.

Рельсовый путь состоит из основания – земляного полотна, балластного слоя, шпал и рельсов со скреплениями.

Земляное полотно вместе с искусственными сооружениями составляет важнейшую часть рельсовых путей и называется нижним строением пути.

Балластный слой, шпалы, рельсы и рельсовые скрепления называют верхним строением пути.

Звенья рельсов соединяются между собой и образуют «нитку» рельсового пути. Две нитки составляют рельсовую колею. Расстояние между внутренними гранями головок рельсов называют шириной рельсовой колеи.

Расположение пути определяется трассой, планом и профилем. Ось пути, разбитая на местности (или нанесенная на карту), называется трассой. Проекция трассы на горизонтальную плоскость называется планом пути. Проекция развернутой трассы на вер-тикальную плоскость называется профилем пути.`,
    top: '476px',
    left: '2865px',
    smallScreen: {
      top: '306px',
      left: '1849px',
    }
  },
  {
    header: 'Поезд "Сапсан"',
    picturesFolder: '50',
    picturesNumber: 2,
    text: `«Сапсаны» курсируют между Санкт-Петербургом, Москвой и Нижним Новгородом. С начала эксплуатации (декабрь 2019г.) ими перевезено 33,2 млн пассажиров. Больше половины всего пассажиропотока на линии Санкт-Петербург – Москва приходится именно на «Сапсаны», заполняемость которых в обычное время составляет около 92%, а в «пиковые» периоды превышает 100% (с учетом промежуточных станций). Чтобы удовлетворить спрос и увеличить провозную способность линии сегодня ежедневно курсируют до 30 поездов «Сапсан», из них до 8 поездов – сдвоенными составами по 20 вагонов.

Поезд «Сапсан» произведен немецкой компанией «Сименс». Он развивает скорость до 250 км/ч и полностью адаптирован к особенностям российского климата. Дизайн и оснащение кресел поезда «Сапсан» учитывают специфику передвижения на дальние расстояния, обеспечивая высокий уровень комфорта даже при длительной поездке. Комфортный климат поддерживается в салоне вне зависимости от времени года. Использование современных шумоизолирующих материалов обеспечивает приемлемый уровень шума.

Свое название поезд получил от самой быстрой птицы семейства соколиных. Именно с «Сапсана» на российских железных дорогах была заведена традиция называть скоростные поезда именами птиц. В настоящее время существуют также поезда «Стриж», «Иволга» и «Ласточка».`,
    top: '346px',
    left: '2930px',
    smallScreen: {
      top: '216px',
      left: '1890px',
    }
  },
  {
    header: 'Здание Ленинградского железнодорожного вокзала в Москве',
    picturesFolder: '51',
    picturesNumber: 3,
    text: `Ленинградский вокзал (до 1855 года – Петербургский вокзал, в 1855 – 1923 годах – Николаевский, в 1923 – 1937 годах – Октябрьский) – пассажирский терминал железнодорожной станции Москва-Пассажирская, расположенный на Комсомольской площади. Является старейшим из девяти вокзалов города. Здание вокзала построено в 1844 – 1851 годах по проект

Строительство прямого железнодорожного сообщения между Москвой и Санкт-Петербургом началось в 1842 году, когда император Николай I подписал соответствующий указ. Дорога должна была стать первым казённым двухпутным полотном в стране. Работы велись одновременно на всей протяжённости пути, но участки вводились в эксплуатацию по очереди. Для конечной станции в Москве изначально планировали использовать участок в черте Садового кольца. Так, рассматривали площади Тверская застава и Трубная. Но от этой задумки отказались из-за шума и возможного пожара от искр из печи паровоза. Строительная комиссия выбрала Каланчёвский пустырь на северо-восточной окраине города.

В 1843 году архитектор Рудольф Желязевич подготовил типовые проекты для вокзалов промежуточных станций железной дороги. Он предложил собственные идеи по организации конечной станции, но их не приняли. Был организован архитектурный конкурс, в котором участвовали Александр Брюллов, Николай Ефимов, а также Константин Тон, работу которого и утвердил Николай I для обеих конечных станций железной дороги. Москвовед Александр Васькин утверждает, что конкурс не проводился, а император сразу поручил проект Тону.`,
    top: '272px',
    left: '3080px',
    smallScreen: {
      top: '172px',
      left: '1995px',
    }
  },
  {
    picturesFolder: '53',
    picturesNumber: 3,
    text: `Железнодорожники внесли неоценимый вклад в дело Победы в Великой Отечественной войне. Возросший объем перевозок, эшелоны с войсками, с эвакуированными предприятиями требовали исключительной слаженности в работе; машинисты сутками не сходили с паровоза.

Портреты воинов-железнодорожников авторы памятника-барельефа увековечили в металле, воссоздав образы по архивным фотографиям.

9 мая 1945 года пришла долгожданная Победа. Поезда возвратились домой с победителями. На родную Свердловскую железную дорогу вернулись Герои Советского Союза: машинист водокачки Григорий Глазунов и контролер Михаил Жбанов, счетовод Аркадий Коняев и шофер Иван Мозжаров, слесарь Константин Пылаев и помощник машиниста Михаил Санычев, столяр Максим Чистяков и многие, многие другие. На Свердловской железной дороге 17 человек удостоены звания Героя Советского Союза и 2 – полные кавалеры Ордена Славы.`,
    top: '337px',
    left: '1578px',
    smallScreen: {
      top: '216px',
      left: '1018px',
    }
  },
  {
    header: 'Электропоезд "Ласточка"',
    picturesFolder: '54',
    picturesNumber: 1,
    text: `«Ласточки» – подвижной состав нового поколения, спроектированный с учетом российских климатических особенностей, технологических стандартов, специальных требований к безопасности и защите окружающей среды. В вагонах поддерживается оптимальный микроклимат, есть розетки для подзарядки мобильных устройств, специальные площадки для размещения крупногабаритного багажа и биотуалеты. Особое внимание уделено пассажирам с ограниченными возможностями здоровья – в составе имеются места для инвалидных колясок. Производят «Ласточки» в г. Верхняя Пышма Свердловской области на заводе «Уральские локомотивы».`,
    top: '421px',
    left: '2616px',
    smallScreen: {
      top: '273px',
      left: '1680px',
    }
  },
  {
    header: 'Подстаканник',
    picturesFolder: '55',
    picturesNumber: 1,
    text: `Подстаканник – символ железнодорожного путешествия, милый, уютный и удобный, свидетель долгих разговоров в поезде. 

Фигура подстаканника на барельефе обладает особыми, магическими свойствами. Прикоснитесь к нему и ожидайте чудесных событий!`,
    top: '519px',
    left: '1823px',
    smallScreen: {
      top: '333px',
      left: '1173px',
    }
  },
]
